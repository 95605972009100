.index-page {
  height: 100vh;
  max-height: -moz-available;
  max-height: -webkit-fill-available;
  max-height: fill-available;
  overflow: hidden;
}

.console {
  width: 40%;
  background: #f7f7f7;
}

.console .console-header {
  -webkit-user-select: none;
  user-select: none;
  -khtml-user-select: none;
  -o-user-select: none;
  background: #333;
  justify-content: space-between;
  align-items: center;
  padding: 6px 12px;
  display: flex;
}

.console .console-header h4 {
  color: #999;
}

.console .console-header button {
  height: 24px;
  vertical-align: middle;
  background: #f7e04b;
  border: none;
  padding: 0 8px;
}

.console .console-header button i {
  vertical-align: text-bottom;
  font-size: 18px;
}

.console .console-header button span {
  font-size: 14px;
}

.console iframe {
  width: 0;
  height: 0;
  visibility: hidden;
  border: none;
}

.code {
  height: calc(100vh - 40px);
  width: 60%;
  background: #193549;
}

.code .code-header {
  -webkit-user-select: none;
  user-select: none;
  -khtml-user-select: none;
  -o-user-select: none;
  background: #333;
  justify-content: space-between;
  align-items: center;
  padding: 6px 12px;
  display: flex;
}

.code .code-header button {
  height: 24px;
  vertical-align: middle;
  background: #f7e04b;
  border: none;
  padding: 0 8px;
}

.code .code-header button i {
  vertical-align: text-bottom;
  font-size: 18px;
}

.code .code-header button span {
  font-size: 14px;
}

.code .code-header .project-link {
  color: #999;
  align-items: center;
  display: flex;
}

.code .code-header .project-link span {
  color: #ddd;
  margin: 0 4px;
}

.code .code-header .project-link input {
  color: #999;
  height: 24px;
  background: none;
  border: none;
  padding: 0 4px;
}

.code .code-header .project-link input:focus {
  background: #444;
}

.code #jsCode {
  height: calc(100vh - 76px);
  width: 100%;
  color: #fff;
  background: #444;
  border: none;
  padding: 16px;
  font-size: 18px;
  display: block;
}

.code #jsCode:focus, .code #jsCode:active {
  box-shadow: none;
  outline: none;
}

@keyframes fadeInDown {
  from {
    opacity: 0;
    transform: translate3d(0, -10px, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.editor {
  width: 100%;
  display: flex;
}

.editor .console {
  height: calc(100vh - 40px);
}

.editor .console #console-logs {
  height: calc(100vh - 76px);
  overflow: auto;
}

.editor .console #console-logs-empty {
  text-align: center;
  visibility: hidden;
  height: 0;
  opacity: 0;
  justify-content: center;
  align-items: center;
  padding: 0 32px;
  transition: opacity .3s ease-in;
  display: flex;
  overflow: auto;
}

.editor .console #console-logs-empty p {
  color: #999;
  margin-bottom: 16px;
}

.editor .console #console-logs-empty button {
  height: 28px;
  background: #f7e04b;
  border: none;
  padding: 0 8px;
}

.editor .console #console-logs-empty.active {
  visibility: visible;
  height: calc(100vh - 76px);
  opacity: 1;
}

.editor .console .console-line-item {
  animation-name: fadeInDown;
  animation-duration: .15s;
  animation-timing-function: ease-in-out;
  animation-fill-mode: both;
  animation-delay: calc(var(--animation-order) * 90ms);
  white-space: pre-wrap;
  border-bottom: 1px solid #ddd;
  padding: 4px 8px 4px 44px;
  font-size: 14px;
  line-height: 18px;
}

.editor .console .console-line-item:before {
  counter-increment: consoleLogCounter;
  content: counter(consoleLogCounter);
  color: #666;
  width: 20px;
  text-align: center;
  height: 20px;
  background: #ecead7;
  border-radius: 4px;
  margin-right: 12px;
  font-size: 10px;
  line-height: 20px;
  display: inline-block;
  position: absolute;
  left: 6px;
}

.editor .console .console-line-item span {
  margin: 0 4px;
}

.editor .console .console-line-item span.object {
  color: #34495e;
}

.editor .console .console-line-item span.string {
  color: #e74c3c;
}

.editor .console .console-line-item span.number {
  color: #2980b9;
}

.editor .console .console-line-item.error {
  color: #ce2417;
  background: #e8a1a145;
}

@media (max-width: 480px) {
  .editor {
    flex-direction: column;
  }

  .editor .code, .editor .console {
    width: 100%;
  }

  .editor .code {
    height: 60%;
  }

  .editor .console {
    height: 40%;
  }
}

.console-line-item-switch {
  height: 0;
  width: 0;
  display: none;
}

.console-line-item-switch + label:before {
  content: "";
  width: 0;
  height: 0;
  cursor: pointer;
  border-top: 4px solid #0000;
  border-bottom: 4px solid #0000;
  border-left: 4px solid #999;
  position: absolute;
  top: 10px;
  left: 32px;
}

.console-line-item-switch + label + .console-line-item-content {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.console-line-item-switch:checked + label:before {
  border-top: 4px solid #333;
  border-left: 4px solid #0000;
  border-right: 4px solid #0000;
  top: 13px;
}

.console-line-item-switch:checked + label + .console-line-item-content {
  white-space: initial;
  overflow: initial;
  text-overflow: initial;
}

.layout-separator {
  width: 3px;
  opacity: 0;
  z-index: 4;
  cursor: col-resize;
  opacity: .8;
  background-color: #232323;
  border-left: 1px solid #0000;
  border-right: 1px solid #0000;
  margin: 0 -1px;
}

.layout-separator:hover {
  opacity: .4;
}

/*# sourceMappingURL=index.06582b85.css.map */
